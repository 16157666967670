import React from "react";
import { downloadFile } from "../functions/Api";
import Button from "@material-ui/core/Button";

class DetailsCard extends React.PureComponent {
  state = {};

  downloadFileApiFetch = (fileName, token) => {
    fileName = fileName
      .replace("/", "%2F")
      .replace("/", "%2F")
      .replace("/", "%2F")
      .replace("/", "%2F");
    downloadFile(fileName, token);
  };

  handleClickDownload = () => {
    this.downloadFileApiFetch(
      this.props.FilePath,
      sessionStorage.getItem("access_token")
    );
  };

  render() {
    /* const {
      FileID,
      SongID,
      FileName,
      SongKey,
      FileType,
      FileCode,
      FilePath,
      Description,
      id,
    } = this.props;
*/
    const { FileName } = this.props;

    return (
      <div className="tc fl w-100">
        <article className="cf pa2">
          <div className="fl w-80 tc">
            <article className="pa1" data-name="slab-stat-large">
              <h4 className="f6">{FileName}</h4>
            </article>
          </div>
          <div className="fl w-20 tc">
            <article className="pa1" data-name="slab-stat-large">
              <Button
                variant="outlined"
                color="primary"
                onClick={this.handleClickDownload}
              >
                下載
              </Button>
            </article>
          </div>
        </article>
      </div>
    );
  }
}

export default DetailsCard;
