import React from "react";
import DetailsCard from "./DetailsCard";
import {
  CanDownloadBasicFiles,
  CanDownloadSpecialFiles,
} from "../functions/Permission";

const DetailsCardList = ({ file, permission }) => {
  return (
    <div>
      {Array.from(file).map((user, i) => {
        if (
          (CanDownloadBasicFiles(permission) &&
            (
              (file[i].FileName.search(/^((?!\.[Mm][Uu][Ss][Xx]*$).)*$/) !== -1) &&
              (file[i].FileName.search(/^((?![Oo][Rr][Ii][Gg]\.).)*$/) !== -1)
            )
          ) ||
          (CanDownloadSpecialFiles(permission) &&
            (
              (file[i].FileName.search(/\.[Mm][Uu][Ss][Xx]*$/) !== -1) ||
              (file[i].FileName.search(/[Oo][Rr][Ii][Gg]\./) !== -1)
            )
          )
        ) {
          return (
            <DetailsCard
              key={i}
              FileID={file[i].FileID}
              SongID={file[i].SongID}
              FileName={file[i].FileName}
              SongKey={file[i].SongKey}
              FileType={file[i].FileType}
              FileCode={file[i].FileCode}
              FilePath={file[i].FilePath}
              Description={file[i].Description}
            />
          );
        }
        return [];
      })}
    </div>
  );
};

export default DetailsCardList;
