import {
  IS_MEMBER_CAN_DL_BASIC_FILES,
  IS_MEMBER_CAN_DL_SPECIAL_FILES,
} from "../ConfigFile";

export function CanDownloadBasicFiles(permission) {
  return (
    (permission & IS_MEMBER_CAN_DL_BASIC_FILES) === IS_MEMBER_CAN_DL_BASIC_FILES
  );
}

export function CanDownloadSpecialFiles(permission) {
  return (
    (permission & IS_MEMBER_CAN_DL_SPECIAL_FILES) ===
    IS_MEMBER_CAN_DL_SPECIAL_FILES
  );
}
