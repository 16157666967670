import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { getFileApi } from "../functions/Api";
import DetailsCardList from "../components/DetailsCardList";
import { IS_NOT_MEMBER } from "../ConfigFile";

//import { AppProvider, LoginContext } from "../AppProvider";
import { LoginContext } from "../AppProvider";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class AlertDialogSlide extends React.PureComponent {
  static contextType = LoginContext;

  state = {
    open: false,
    responseArray: [],
  };

  handleClickOpen = () => {
    this.getFileApiFetch(this.props.fileID);
  };

  getFileApiFetch = async (fileID) => {
    this.response_data1 = await getFileApi(
      fileID,
      sessionStorage.getItem("access_token")
    );
    //console.log("getFileApiFetch result: " + this.response_data1);
    if (this.response_data1.length === 0) {
      this.setState({ responseArray: "no_result" });
    } else {
      this.setState({ responseArray: this.response_data1 });
    }
    this.setState({ open: true });
  };

  componentWillMount() {
    //console.log("Dialog componentWillMount context", this.context);
  }
  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { open, responseArray } = this.state;
    const { songName } = this.props;
    //const { isLogin, isYanFookMember } = this.context;
    const { yanFookMemberPermission } = this.context;

    if (responseArray === "no_result") {
      return (
        <h1 className="f6 bg-light-gray mv0 br3 black-100 mv0 pv2 ph3">
          尚未有檔案可供下載
        </h1>
      );
    } else if (yanFookMemberPermission > IS_NOT_MEMBER) {
      //console.log("Perm: " + yanFookMemberPermission);
      return (
        <div>
          <Button
            variant="outlined"
            color="primary"
            //color="bg-light-gray"
            onClick={this.handleClickOpen}
          >
            顯示詩歌檔案
          </Button>
          <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={this.handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle id="alert-dialog-slide-title">{songName}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                只供恩福內部使用
              </DialogContentText>
              <DetailsCardList
                file={responseArray}
                permission={yanFookMemberPermission}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleClose} color="primary">
                關閉
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      );
    } else if (yanFookMemberPermission === IS_NOT_MEMBER) {
      return (
        <h1 className="f6 bg-light-gray mv0 br3 black-100 mv0 pv2 ph3">
          你的Gmail尚未在敬拜部登記，因此無法下載檔案
        </h1>
      );
    }
    else {
      return (
        // REMOVE MESSAGE FOR NON-MEMBER
        /*
        <h1 className="f6 bg-light-gray mv0 br3 black-100 mv0 pv2 ph3">
          如欲下載檔案，請先登入
        </h1>
        */
       <div></div>
      );
    }
  }
}

export default AlertDialogSlide;
