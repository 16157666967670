import React from "react";
import AlertDialogSlide from "../containers/AlertDialogSlide";

import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Linkify from "react-linkify";
import Highlighter from "react-highlight-words";
import { makeStyles } from "@material-ui/core/styles";

// 1. song_code
// 2. song_name
// 3. main_cat
// 4. sub_cat
// 5. related_verses
// 6. keywords
// 7. composer
// 8. orisong
// 9. arrangedby
// 10. lyrics_writer
// 11. translator
// 12. lyrics
// 13. language
// 14. remarks
// 15. related_song
// 16. publishing
const useStyles = makeStyles((theme) => ({
  expansionPanelSummaryContent: {
    margin: 0,
    "&.expanded": {
      margin: 0,
    },
  },
}));

const Card = (props) => {
  const classes = useStyles();
  const hStyle = { fontWeight: "bold", color: "navy" };
  const handleExpansionChange = (e, expanded) => {
    props.handleCardExpansionChange(e, props.index);
  };
  const addLineBreaks = (string) =>
    string != null
      ? string.split("\n").map((text, index) => (
          <React.Fragment key={`${text}-${index}`}>
            <Highlighter
              highlightClassName="highlight"
              searchWords={[props.search_text, props.search_lyrics]}
              autoEscape={true}
              textToHighlight={text}
            />
            <br />
          </React.Fragment>
        ))
      : "";
  const replaceLinks = (string) => {
    if (string != null) {
      const regexLink = /<link>(.*)<\/link>/gi;
      let parts = string.split(regexLink);
      for (var i = 0; i < parts.length; i += 2) {
        parts[i] = (
          <Highlighter
            key={i}
            highlightClassName="highlight"
            searchWords={[props.search_text, props.search_lyrics]}
            autoEscape={true}
            textToHighlight={parts[i]}
          />
        );
      }
      for (i = 1; i < parts.length; i += 2) {
        console.log(parts[i - 1] + ", " + parts[i]);
        parts[i] = (
          <a href={parts[i]} key={i} target="_blank" rel="noopener noreferrer">
            {parts[i]}
          </a>
        );
      }
      return <div>{parts}</div>;
    } else {
      return "";
    }
  };
  return (
    <div className="tc fl w-100">
      <ExpansionPanel
        expanded={props.is_expanded}
        onChange={handleExpansionChange}
      >
        <ExpansionPanelSummary
          classes={{
            content: classes.expansionPanelSummaryContent,
            expanded: "expanded",
          }}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div className="cf tc fl w-100">
            <div className="fl f6 w-10 pv1">
              <h3 className="v-mid">
                <Highlighter
                  highlightClassName="highlight"
                  searchWords={[props.search_text]}
                  autoEscape={true}
                  textToHighlight={props.song_code}
                />
              </h3>
            </div>

            <div className="fl f6 w-50 pv1">
              <h3 className="v-mid">
                <Highlighter
                  highlightClassName="highlight"
                  searchWords={[props.search_text]}
                  autoEscape={true}
                  textToHighlight={props.song_name.split("|").join(", ")}
                />
              </h3>
            </div>
            <div className="fl w-20 tc pv1 ">
              <article className="pa2-ns" data-name="slab-stat-large">
                <div className="cf">
                  <dd className="f6 fw4 ml0 black-60">主分類:</dd>
                  <dd className="f6 fw4 ml0">
                    <Highlighter
                      highlightClassName="highlight"
                      searchWords={[props.search_text]}
                      autoEscape={true}
                      textToHighlight={props.main_cat.split("|").join(", ")}
                    />
                  </dd>
                </div>
              </article>
            </div>
            <div className="fl w-20 tc pv1 ">
              <article className="pa2-ns" data-name="slab-stat-large">
                <div className="cf">
                  <dd className="f6 fw4 ml0 black-60">次分類:</dd>
                  <dd className="f6 fw4 ml0">
                    <Highlighter
                      highlightClassName="highlight"
                      searchWords={[props.search_text]}
                      autoEscape={true}
                      textToHighlight={props.sub_cat.split("|").join(", ")}
                    />
                  </dd>
                </div>
              </article>
            </div>
          </div>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <div className="tc fl w-100">
            <article className="center mw8-ns br3 hidden ba b--black-10 mv0">
              <h1 className="f5 bg-light-gray black-100 mv0 pv2 ph3">
                詩歌資料
              </h1>
              <div className="pa3 bt b--black-10">
                <article className="cf pa0">
                  <div className="fl w-33   tc">
                    <article className="pa1 pa1-ns" data-name="slab-stat-large">
                      <div className="cf">
                        <dd className="f6 fw4 ml0 black-60" style={hStyle}>
                          語言:
                        </dd>
                        <dd className="f5 fw4 ml0">
                          <Highlighter
                            highlightClassName="highlight"
                            searchWords={[props.search_text]}
                            autoEscape={true}
                            textToHighlight={props.language
                              .split("|")
                              .join(", ")}
                          />
                        </dd>
                      </div>
                    </article>
                  </div>
                  <div className="fl w-33 tc">
                    <article className="pa1 pa1-ns" data-name="slab-stat-large">
                      <div className="cf">
                        <dd className="f6 fw4 ml0 black-60" style={hStyle}>
                          經文:
                        </dd>
                        <dd className="f5 fw4 ml0">
                          <Highlighter
                            highlightClassName="highlight"
                            searchWords={[props.search_text]}
                            autoEscape={true}
                            textToHighlight={props.related_verses
                              .split("|")
                              .join(", ")}
                          />
                        </dd>
                      </div>
                    </article>
                  </div>
                  <div className="fl w-33 tc">
                    <article className="pa1 pa1-ns" data-name="slab-stat-large">
                      <div className="cf">
                        <dd className="f6 fw4 ml0 black-60" style={hStyle}>
                          關鍵字:
                        </dd>
                        <dd className="f5 fw4 ml0">
                          <Highlighter
                            highlightClassName="highlight"
                            searchWords={[props.search_text]}
                            autoEscape={true}
                            textToHighlight={props.keywords
                              .split("|")
                              .join(", ")}
                          />
                        </dd>
                      </div>
                    </article>
                  </div>
                </article>
                <article className="cf pa0">
                  <div className="fl w-50 tc">
                    <article className="pa1 pa1-ns" data-name="slab-stat-large">
                      <div className="cf">
                        <dd className="f6 fw4 ml0 black-60" style={hStyle}>
                          作曲:
                        </dd>
                        <dd className="f5 fw4 ml0">
                          <Highlighter
                            highlightClassName="highlight"
                            searchWords={[props.search_text]}
                            autoEscape={true}
                            textToHighlight={props.composer
                              .split("|")
                              .join(", ")}
                          />
                        </dd>
                      </div>
                    </article>
                  </div>
                  <div className="fl w-50 tc">
                    <article className="pa1 pa1-ns" data-name="slab-stat-large">
                      <div className="cf">
                        <dd className="f6 fw4 ml0 black-60" style={hStyle}>
                          編曲:
                        </dd>
                        <dd className="f5 fw4 ml0">
                          <Highlighter
                            highlightClassName="highlight"
                            searchWords={[props.search_text]}
                            autoEscape={true}
                            textToHighlight={props.arrangedby
                              .split("|")
                              .join(", ")}
                          />
                        </dd>
                      </div>
                    </article>
                  </div>
                </article>
                <article className="cf pa0">
                  <div className="fl w-50 tc">
                    <article className="pa1 pa1-ns" data-name="slab-stat-large">
                      <div className="cf">
                        <dd className="f6 fw4 ml0 black-60" style={hStyle}>
                          作詞:
                        </dd>
                        <dd className="f5 fw4 ml0">
                          <Highlighter
                            highlightClassName="highlight"
                            searchWords={[props.search_text]}
                            autoEscape={true}
                            textToHighlight={props.lyrics_writer
                              .split("|")
                              .join(", ")}
                          />
                        </dd>
                      </div>
                    </article>
                  </div>
                  <div className="fl w-50 tc">
                    <article className="pa1 pa1-ns" data-name="slab-stat-large">
                      <div className="cf">
                        <dd className="f6 fw4 ml0 black-60" style={hStyle}>
                          譯詞:
                        </dd>
                        <dd className="f5 fw4 ml0">
                          <Highlighter
                            highlightClassName="highlight"
                            searchWords={[props.search_text]}
                            autoEscape={true}
                            textToHighlight={props.translator
                              .split("|")
                              .join(", ")}
                          />
                        </dd>
                      </div>
                    </article>
                  </div>
                </article>
                <article className="cf pa0">
                  <div className="fl w-50 tc">
                    <article className="pa1 pa1-ns" data-name="slab-stat-large">
                      <div className="cf">
                        <dd className="f6 fw4 ml0 black-60" style={hStyle}>
                          原曲:
                        </dd>
                        <dd className="f5 fw4 ml0">
                          <Highlighter
                            highlightClassName="highlight"
                            searchWords={[props.search_text]}
                            autoEscape={true}
                            textToHighlight={props.orisong
                              .split("|")
                              .join(", ")}
                          />
                        </dd>
                      </div>
                    </article>
                  </div>
                  <div className="fl w-50 tc">
                    <article className="pa1 pa1-ns" data-name="slab-stat-large">
                      <div className="cf">
                        <dd className="f6 fw4 ml0 black-60" style={hStyle}>
                          機構:
                        </dd>
                        <dd className="f5 fw4 ml0">
                          <Highlighter
                            highlightClassName="highlight"
                            searchWords={[props.search_text]}
                            autoEscape={true}
                            textToHighlight={props.publishing
                              .split("|")
                              .join(", ")}
                          />
                        </dd>
                      </div>
                    </article>
                  </div>
                </article>
                <article className="cf pa0">
                  <div className="fl w-50 tc">
                    <article className="pa1 pa1-ns" data-name="slab-stat-large">
                      <div className="cf">
                        <dd className="f6 fw4 ml0 black-60" style={hStyle}>
                          專輯:
                        </dd>
                        <dd className="f5 fw4 ml0">
                          <Highlighter
                            highlightClassName="highlight"
                            searchWords={[props.search_text]}
                            autoEscape={true}
                            textToHighlight={props.album.split("|").join(", ")}
                          />
                        </dd>
                      </div>
                    </article>
                  </div>
                  <div className="fl w-50 tc">
                    <article className="pa1 pa1-ns" data-name="slab-stat-large">
                      <div className="cf">
                        <dd className="f6 fw4 ml0 black-60" style={hStyle}>
                          版權:
                        </dd>
                        <dd className="f5 fw4 ml0">
                          <Highlighter
                            highlightClassName="highlight"
                            searchWords={[props.search_text]}
                            autoEscape={true}
                            textToHighlight={props.copyright
                              .split("|")
                              .join(", ")}
                          />
                        </dd>
                      </div>
                    </article>
                  </div>
                </article>
                <article className="cf pa0">
                  <div className="fl w-50 tc">
                    <article className="pa1 pa1-ns" data-name="slab-stat-large">
                      <div className="cf">
                        <dd className="f6 fw4 ml0 black-60" style={hStyle}>
                          相關詩歌:
                        </dd>
                        <dd className="f5 fw4 ml0">
                          <Highlighter
                            highlightClassName="highlight"
                            searchWords={[props.search_text]}
                            autoEscape={true}
                            textToHighlight={props.related_song
                              .split("|")
                              .join(", ")}
                          />
                        </dd>
                      </div>
                    </article>
                  </div>

                  <div className="fl w-50 tc">
                    <article className="pa1 pa1-ns" data-name="slab-stat-large">
                      <div className="cf">
                        <dd className="f6 fw4 ml0 black-60" style={hStyle}>
                          備註:
                        </dd>
                        <Linkify>
                          <dd className="f5 fw4 ml0 pa0">
                            {replaceLinks(props.remarks.split("|").join(", "))}
                          </dd>
                        </Linkify>
                      </div>
                    </article>
                  </div>
                </article>
                <article className="cf pa0">
                  <div className="fl w-100 tc">
                    <article className="pa1 pa1-ns" data-name="slab-stat-large">
                      <div className="cf">
                        <dd className="f6 fw4 ml0 black-60" style={hStyle}>
                          歌詞:
                        </dd>
                        <dd className="f5 fw4 ml0 pa0">
                          {addLineBreaks(props.lyrics)}
                        </dd>
                      </div>
                    </article>
                  </div>
                </article>
                <AlertDialogSlide
                  songName={props.song_name}
                  fileID={props.id}
                />
              </div>
            </article>
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  );
};

export default Card;
