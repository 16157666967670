//export const SERVER_IP = "http://yfhymn.org.hk:3000";
export const SERVER_IP = "http://yfhymn.org.hk";

export async function searchAPI(
  song_code,
  song_name,
  main_cat,
  sub_cat,
  related_verses,
  keywords,
  composer,
  orisong,
  arrangedby,
  lyrics_writer,
  translator,
  lyrics,
  language,
  publishing
) {
  try {
    var url = SERVER_IP + "/api/hymns/searchSong/";
    //console.log("url", url);

    var formData = new URLSearchParams();
    formData.append("song_code", song_code);
    formData.append("song_name", song_name);
    formData.append("main_cat", main_cat);
    formData.append("sub_cat", sub_cat);
    formData.append("related_verses", related_verses);
    formData.append("keywords", keywords);
    formData.append("composer", composer);
    formData.append("orisong", orisong);
    formData.append("arrangedby", arrangedby);
    formData.append("lyrics_writer", lyrics_writer);
    formData.append("translator", translator);
    formData.append("lyrics", lyrics);
    formData.append("language", language);
    formData.append("publishing", publishing);

    const response = await fetch(url, {
      mode: "cors",
      body: formData,
      method: "post",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "Access-Control-Allow-Origin": "*",
      },
    });
    const data = await response.json();
    //console.log(data);
    return data;
  } catch (ex) {
    return false;
  }
}

export async function basicSearchAPI(target) {
  try {
    var url = SERVER_IP + "/api/hymns/basicSearch/";
    //console.log("url", url);
    //console.log("url para", target);

    var formData = new URLSearchParams();
    formData.append("target", target);

    const response = await fetch(url, {
      mode: "cors",
      body: formData,
      method: "post",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "Access-Control-Allow-Origin": "*",
      },
    });
    const data = await response.json();
    //console.log(data);
    return data;
  } catch (ex) {
    return false;
  }
}

export async function getFileApi(fileName, token) {
  try {
    var url =
      SERVER_IP + "/api/hymns/getFileWithToken/" + fileName + "/" + token;

    //console.log("url", url);
    const response = await fetch(url, {
      mode: "cors",
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });
    //const response = await fetch(url, {mode: 'cors'})
    const data = await response.json();
    //console.log(data);
    return data;
  } catch (ex) {
    return false;
  }
}

export async function validToken(token) {
  try {
    var url = SERVER_IP + "/api/hymns/validToken/";

    //console.log("url", url);
    var formData = new URLSearchParams();
    formData.append("token", token);

    const response = await fetch(url, {
      mode: "cors",
      body: formData,
      method: "post",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "Access-Control-Allow-Origin": "*",
      },
    });
    const data = await response.json();
    //console.log(data);
    return data;
  } catch (ex) {
    return false;
  }
}

export async function authYanFookMember(email) {
  try {
    var url = SERVER_IP + "/api/hymns/authYanFookMember/";

    //console.log("url", url);
    var formData = new URLSearchParams();
    formData.append("email", email);
    const response = await fetch(url, {
      mode: "cors",
      body: formData,
      method: "post",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "Access-Control-Allow-Origin": "*",
      },
    });
    const data = await response.json();
    //console.log(data);
    return data;
  } catch (ex) {
    return false;
  }
}

export function downloadFile(fileName, token) {
  try {
    var url = SERVER_IP + "/api/hymns/downloadFile/" + fileName + "/" + token;

    window.open(url);
  } catch (ex) {}
}
