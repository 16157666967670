import React from "react";
import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import { GoogleLogin, GoogleLogout } from "react-google-login";
import { validToken, authYanFookMember } from "../functions/Api";
import CircularProgress from "@material-ui/core/CircularProgress";
import { IS_NOT_MEMBER, DID_NOT_CHECK_MEMBER } from "../ConfigFile";

//import { AppProvider, LoginContext } from "../AppProvider";
import { LoginContext } from "../AppProvider";

const styles = {
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
};

class ButtonAppBar extends React.Component {
  static contextType = LoginContext;

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentWillMount() {
    //console.log("ButtonAppBar componentWillMount context", this.context);
    if (sessionStorage.getItem("access_token") != null) {
      if (this.context.yanFookMemberPermission === DID_NOT_CHECK_MEMBER) {
        //fetch check login
        this.tokenApiFetch(sessionStorage.getItem("access_token"));
      } else {
        //check logined already
        this.context.setLogin(true);
      }
    } else {
      this.context.setLogin(false);
    }
  }

  signup(res, type) {
    let postData;
    // if (type === 'facebook' && res.email) {
    //   postData = {
    //     name: res.name,
    //     provider: type,
    //     email: res.email,
    //     provider_id: res.id,
    //     token: res.accessToken,
    //     provider_pic: res.picture.data.url
    //   };
    // }

    //if (type === 'google' && res.w3.U3) {
    if (type === "google" && res.tokenObj) {
      postData = {
        // name: res.w3.ig,
        // provider: type,
        // email: res.w3.U3,
        // provider_id: res.El,
        // token: res.Zi.access_token,
        // provider_pic: res.w3.Paa
        token: res.tokenObj.access_token,
        email: res.profileObj.email,
      };
      if (postData != null) {
        //console.log("user access token", postData.token);
        sessionStorage.setItem("access_token", postData.token);

        this.context.setLogin(true);

        //console.log("user email", postData.email);
        this.authYanFookMemberApiFetch(postData.email);
      } else {
        //console.log("google return null access token", "error");
        sessionStorage.removeItem("access_token");

        this.context.setLogin(false);
      }
    }
  }

  responseGoogle = (response) => {
    /*console.log("login: ", response);
    console.log(
      "login tokenObj.access_token : ",
      response.tokenObj.access_token
    );
    */
    this.signup(response, "google");
  };

  logout = (response) => {
    //console.log("logout: ", response);
    sessionStorage.removeItem("access_token");

    // this.setState({ isloginned: false });

    this.context.setLogin(false);
    this.context.setYanFookMemberPermission(DID_NOT_CHECK_MEMBER);
  };

  tokenApiFetch = async (token) => {
    this.response_data = await validToken(token);
    if (this.response_data.length !== 0) {
      //console.log("tokenApiFetch", this.response_data);
      if (this.response_data.email != null) {
        //console.log("tokenApiFetch", this.response_data.email);
        this.context.setLogin(true);

        this.authYanFookMemberApiFetch(this.response_data.email);
      } else {
        this.context.setLogin(false);
        this.context.setYanFookMemberPermission(DID_NOT_CHECK_MEMBER);

        sessionStorage.removeItem("access_token");
      }
    }
  };

  authYanFookMemberApiFetch = async (email) => {
    this.response_data = await authYanFookMember(email);
    if (this.response_data != null) {
      //console.log("authYanFookMemberApiFetch", this.response_data);
      if (this.response_data.length > 0) {
        //console.log("status", this.response_data.status);
        this.context.setYanFookMemberPermission(
          this.response_data[0].Permission
        );
      } else {
        //console.log(" authYanFookMemberApiFetch", "error");
        this.context.setYanFookMemberPermission(IS_NOT_MEMBER);
      }
    }
  };

  render() {
    const { classes } = this.props;
    const { isLogin, yanFookMemberPermission } = this.context;

    //console.log("render AppBar context", this.context);

    if (isLogin === false) {
      return (
        <div className={classes.root}>
          <AppBar position="static">
            <Toolbar>
              <Link
                to="/"
                style={{ color: "inherit", textDecoration: "inherit" }}
              >
                <img
                  src="yfhymn_logo_rounded.png"
                  alt="logo"
                  className="Custom-app-logo"
                />
              </Link>
              <Typography
                align="left"
                variant="h6"
                color="inherit"
                className={classes.grow}
              >
                <Link
                  to="/"
                  style={{ color: "inherit", textDecoration: "inherit" }}
                >
                  恩福詩歌庫
                </Link>
              </Typography>
              <GoogleLogin
                clientId="127800555540-d9sncaj85r6oqrm9hdhbpr759ft0ti1l.apps.googleusercontent.com"
                buttonText="以GOOGLE登入"
                onSuccess={this.responseGoogle}
                onFailure={this.responseGoogle}
              />
            </Toolbar>
          </AppBar>
        </div>
      );
    } else {
      if (yanFookMemberPermission === DID_NOT_CHECK_MEMBER) {
        return (
          <div className={classes.root}>
            <AppBar position="static">
              <Toolbar>
                <Link
                  to="/"
                  style={{ color: "inherit", textDecoration: "inherit" }}
                >
                  <img
                    src="yfhymn_logo_rounded.png"
                    alt="logo"
                    className="Custom-app-logo"
                  />
                </Link>
                <Typography
                  align="left"
                  variant="h6"
                  color="inherit"
                  className={classes.grow}
                >
                  <Link
                    to="/"
                    style={{ color: "inherit", textDecoration: "inherit" }}
                  >
                    恩福詩歌庫
                  </Link>{" "}
                </Typography>
                <CircularProgress
                  className={classes.progress}
                  color="secondary"
                />
              </Toolbar>
              <div className="tc bg-red"></div>
            </AppBar>
          </div>
        );
      } else if (yanFookMemberPermission > IS_NOT_MEMBER) {
        return (
          <div className={classes.root}>
            <AppBar position="static">
              <Toolbar>
                <Link
                  to="/"
                  style={{ color: "inherit", textDecoration: "inherit" }}
                >
                  <img
                    src="yfhymn_logo_rounded.png"
                    alt="logo"
                    className="Custom-app-logo"
                  />
                </Link>
                <Typography
                  align="left"
                  variant="h6"
                  color="inherit"
                  className={classes.grow}
                >
                  <Link
                    to="/"
                    style={{ color: "inherit", textDecoration: "inherit" }}
                  >
                    恩福詩歌庫
                  </Link>{" "}
                </Typography>
                <GoogleLogout
                  clientId="127800555540-d9sncaj85r6oqrm9hdhbpr759ft0ti1l.apps.googleusercontent.com"
                  buttonText="登出"
                  onLogoutSuccess={this.logout}
                />
              </Toolbar>
              <div className="tc bg-red"></div>
            </AppBar>
          </div>
        );
      } else if (yanFookMemberPermission === IS_NOT_MEMBER) {
        return (
          <div className={classes.root}>
            <AppBar position="static">
              <Toolbar>
                <Link
                  to="/"
                  style={{ color: "inherit", textDecoration: "inherit" }}
                >
                  <img
                    src="yfhymn_logo_rounded.png"
                    alt="logo"
                    className="Custom-app-logo"
                  />
                </Link>
                <Typography
                  align="left"
                  variant="h6"
                  color="inherit"
                  className={classes.grow}
                >
                  <Link
                    to="/"
                    style={{ color: "inherit", textDecoration: "inherit" }}
                  >
                    恩福詩歌庫
                  </Link>
                </Typography>
                <GoogleLogout
                  clientId="127800555540-d9sncaj85r6oqrm9hdhbpr759ft0ti1l.apps.googleusercontent.com"
                  buttonText="登出"
                  onLogoutSuccess={this.logout}
                />
              </Toolbar>
              <div className="tc bg-red">
                <f3>
                  你的Gmail尚未在敬拜部登記，您無法使用所有功能，請聯絡我們。
                </f3>
              </div>
            </AppBar>
          </div>
        );
      } else {
        return <div>Error</div>;
      }
    }
  }
}

// ButtonAppBar.propTypes = {
//   classes: PropTypes.object.isRequired,
// };

export default withStyles(styles)(ButtonAppBar);
