import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

const styles = (theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  margin: {
    //margin: theme.spacing.unit,
    margin: theme.spacing(1),
  },
  textField: {
    flexBasis: 400,
  },
});

class OutlinedInputAdornmentsForBasicSearch extends React.Component {
  state = {};

  // 1. song_code
  // 2. song_name
  // 3. main_cat
  // 4. sub_cat
  // 5. related_verses
  // 6. keywords
  // 7. composer
  // 8. orisong
  // 9. arrangedby
  // 10. lyrics_writer
  // 11. translator
  // 12. lyrics
  // 13. language
  _handleKeyDown = (e) => {
    if (e.key === "Enter") {
      console.log("do validate");
      this.props.onEnter();
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <div className="fl w-100">
        <article className="cf">
          <div className="fl w-100  pa1 tc">
            <TextField
              margin="normal"
              fullWidth
              id="search_text"
              value={this.props.target}
              onChange={this.props.handleChange("basic_search_target")}
              className={classNames(classes.margin, classes.textField)}
              variant="outlined"
              label="尋找詩歌"
              onKeyDown={this._handleKeyDown}
            />
          </div>
        </article>
      </div>
    );
  }
}

OutlinedInputAdornmentsForBasicSearch.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(OutlinedInputAdornmentsForBasicSearch);
