import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";

const styles = (theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  margin: {
    //  margin: theme.spacing.unit,
    margin: theme.spacing(1),
  },
  textField: {
    flexBasis: 400,
  },
});

const main_cat_ranges = [
  { value: "", label: "" },
  {
    value: "崇拜讚美",
    label: "崇拜讚美",
    sub_cat_data: [
      { value: "", label: "" },
      { value: "聖父", label: "聖父" },
      { value: "聖子", label: "聖子" },
      { value: "聖靈", label: "聖靈" },
      { value: "三一真神", label: "三一真神" },
    ],
  },
  {
    value: "聖父主神",
    label: "聖父主神",
    sub_cat_data: [
      { value: "", label: "" },
      { value: "聖父", label: "聖父" },
      { value: "神的永能", label: "神的永能" },
      { value: "神的威嚴與能力", label: "神的威嚴與能力" },
      { value: "神的權威與治權", label: "神的權威與治權" },
      { value: "神的聖潔", label: "神的聖潔" },
      { value: "神的慈愛", label: "神的慈愛" },
      { value: "神的憐憫", label: "神的憐憫" },
      { value: "神的榮耀", label: "神的榮耀" },
      { value: "神的信實", label: "神的信實" },
      { value: "神的真實和智慧", label: "神的真實和智慧" },
      { value: "神的仁慈", label: "神的仁慈" },
      { value: "神的公義", label: "神的公義" },
      { value: "神的創造", label: "神的創造" },
      { value: "神的饒恕", label: "神的饒恕" },
      { value: "神的恩典", label: "神的恩典" },
      { value: "神是王", label: "神是王" },
      { value: "神的國度", label: "神的國度" },
      { value: "神是保守者和拯救", label: "神是保守者和拯救" },
      { value: "神是牧者", label: "神是牧者" },
      { value: "神的保護", label: "神的保護" },
      { value: "神的同在", label: "神的同在" },
      { value: "神的供應", label: "神的供應" },
      { value: "神的看顧和保護", label: "神的看顧和保護" },
      { value: "神的應許", label: "神的應許" },
      { value: "神的引導", label: "神的引導" },
      { value: "神的尊貴", label: "神的尊貴" },
      { value: "神的盼望", label: "神的盼望" },
      { value: "神的主權", label: "神的主權" },
      { value: "神的審判", label: "神的審判" },
      { value: "神的掌權", label: "神的掌權" },
    ],
  },
  {
    value: "聖子耶穌",
    label: "聖子耶穌",
    sub_cat_data: [
      { value: "", label: "" },
      { value: "耶穌基督臨世", label: "耶穌基督臨世" },
      { value: "耶穌基督的降生", label: "耶穌基督的降生" },
      { value: "耶穌基督的顯現", label: "耶穌基督的顯現" },
      { value: "耶穌基督地上生活和職事", label: "耶穌基督地上生活和職事" },
      { value: "耶穌基督凱旋進城", label: "耶穌基督凱旋進城" },
      { value: "耶穌基督的十架", label: "耶穌基督的十架" },
      { value: "耶穌基督受苦和受死", label: "耶穌基督受苦和受死" },
      { value: "耶穌基督的寶血", label: "耶穌基督的寶血" },
      { value: "耶穌基督的犧牲", label: "耶穌基督的犧牲" },
      { value: "耶穌基督的復活", label: "耶穌基督的復活" },
      { value: "耶穌基督升天及掌權", label: "耶穌基督升天及掌權" },
      { value: "耶穌基督的最後勝利", label: "耶穌基督的最後勝利" },
      { value: "耶穌基督的再來", label: "耶穌基督的再來" },
      { value: "耶穌基督的永在", label: "耶穌基督的永在" },
      { value: "耶穌基督道成肉身", label: "耶穌基督道成肉身" },
      { value: "耶穌基督的威嚴與能力", label: "耶穌基督的威嚴與能力" },
      { value: "耶穌基督的權威與治權", label: "耶穌基督的權威與治權" },
      { value: "耶穌基督的尊貴", label: "耶穌基督的尊貴" },
      { value: "耶穌基督對我們的愛", label: "耶穌基督對我們的愛" },
      { value: "耶穌基督的名字", label: "耶穌基督的名字" },
      { value: "耶穌基督是王", label: "耶穌基督是王" },
      { value: "耶穌基督是主", label: "耶穌基督是主" },
      { value: "耶穌基督是一切", label: "耶穌基督是一切" },
      { value: "耶穌基督是神的羔羊", label: "耶穌基督是神的羔羊" },
      { value: "耶穌基督是拯救者", label: "耶穌基督是拯救者" },
      { value: "耶穌基督是救贖主", label: "耶穌基督是救贖主" },
      { value: "耶穌基督是釋放者", label: "耶穌基督是釋放者" },
      { value: "耶穌基督是醫治者", label: "耶穌基督是醫治者" },
      { value: "耶穌基督是良友", label: "耶穌基督是良友" },
      { value: "耶穌基督是牧者", label: "耶穌基督是牧者" },
      { value: "耶穌基督是教師", label: "耶穌基督是教師" },
      { value: "耶穌基督是喜樂", label: "耶穌基督是喜樂" },
      { value: "耶穌基督的恩典", label: "耶穌基督的恩典" },
      { value: "耶穌基督的榮耀", label: "耶穌基督的榮耀" },
      { value: "耶穌基督是光", label: "耶穌基督是光" },
      { value: "耶穌基督是根基", label: "耶穌基督是根基" },
      { value: "耶穌基督的保護", label: "耶穌基督的保護" },
      { value: "耶穌基督的拯救", label: "耶穌基督的拯救" },
      { value: "耶穌基督的引導", label: "耶穌基督的引導" },
      { value: "耶穌基督的呼召", label: "耶穌基督的呼召" },
      { value: "耶穌基督的同在", label: "耶穌基督的同在" },
      { value: "耶穌基督的柔和、謙卑", label: "耶穌基督的柔和、謙卑" },
      { value: "耶穌基督是先知、祭司", label: "耶穌基督是先知、祭司" },
      { value: "耶穌基督是中保", label: "耶穌基督是中保" },
      { value: "耶穌基督是代求者", label: "耶穌基督是代求者" },
    ],
  },
  {
    value: "聖靈恩師",
    label: "聖靈恩師",
    sub_cat_data: [
      { value: "", label: "" },
      { value: "聖靈的能力", label: "聖靈的能力" },
      { value: "聖靈的工作", label: "聖靈的工作" },
      { value: "聖靈的更新", label: "聖靈的更新" },
      { value: "聖靈的同在", label: "聖靈的同在" },
      { value: "聖靈的充滿", label: "聖靈的充滿" },
      { value: "聖靈是生命氣息", label: "聖靈是生命氣息" },
      { value: "聖靈真理教導", label: "聖靈真理教導" },
      { value: "保惠師", label: "保惠師" },
      { value: "使人成聖", label: "使人成聖" },
    ],
  },
  {
    value: "聖經",
    label: "聖經",
    sub_cat_data: [{ value: "", label: "" }],
  },
  {
    value: "三一真神",
    label: "三一真神",
    sub_cat_data: [{ value: "", label: "" }],
  },
  {
    value: "神的話語",
    label: "神的話語",
    sub_cat_data: [{ value: "", label: "" }],
  },
  {
    value: "神的子民",
    label: "神的子民",
    sub_cat_data: [
      { value: "", label: "" },
      { value: "認罪", label: "認罪" },
      { value: "確據", label: "確據" },
      { value: "救恩", label: "救恩" },
      { value: "新生命", label: "新生命" },
      { value: "更新", label: "更新" },
    ],
  },
  {
    value: "屬靈成長",
    label: "屬靈成長",
    sub_cat_data: [
      { value: "", label: "" },
      { value: "朝聖", label: "朝聖" },
      { value: "屬靈勝利", label: "屬靈勝利" },
      { value: "屬靈戰爭", label: "屬靈戰爭" },
      { value: "靈裡饑渴慕義", label: "靈裡饑渴慕義" },
      { value: "感恩", label: "感恩" },
      { value: "抱負", label: "抱負" },
      { value: "委身及奉獻", label: "委身及奉獻" },
      { value: "門徒", label: "門徒" },
      { value: "順服", label: "順服" },
      { value: "降服", label: "降服" },
      { value: "安慰", label: "安慰" },
      { value: "鼓勵", label: "鼓勵" },
      { value: "管家職分", label: "管家職分" },
      { value: "信徒服侍", label: "信徒服侍" },
      { value: "信徒關懷", label: "信徒關懷" },
      { value: "忠心", label: "忠心" },
      { value: "勇氣", label: "勇氣" },
      { value: "工作", label: "工作" },
      { value: "休閒", label: "休閒" },
      { value: "教育及知識", label: "教育及知識" },
      { value: "音樂及歌唱", label: "音樂及歌唱" },
      { value: "婚姻", label: "婚姻" },
      { value: "家庭及家人", label: "家庭及家人" },
      { value: "肉體饑渴", label: "肉體饑渴" },
      { value: "身體醫治", label: "身體醫治" },
      { value: "內在平安", label: "內在平安" },
      { value: "世界和平", label: "世界和平" },
      { value: "正義", label: "正義" },
      { value: "盼望", label: "盼望" },
      { value: "我們對神的愛", label: "我們對神的愛" },
      { value: "我們對基督的愛", label: "我們對基督的愛" },
      { value: "彼此相愛", label: "彼此相愛" },
      { value: "愛的禮物", label: "愛的禮物" },
      { value: "彼此饒恕", label: "彼此饒恕" },
      { value: "重擔", label: "重擔" },
      { value: "罪惡", label: "罪惡" },
      { value: "恐懼", label: "恐懼" },
      { value: "悲痛", label: "悲痛" },
      { value: "焦慮與緊張", label: "焦慮與緊張" },
      { value: "永生", label: "永生" },
      { value: "合一", label: "合一" },
      { value: "信心", label: "信心" },
      { value: "異象", label: "異象" },
      { value: "見證", label: "見證" },
      { value: "喜樂", label: "喜樂" },
      { value: "禱告", label: "禱告" },
      { value: "信靠", label: "信靠" },
      { value: "聖潔", label: "聖潔" },
      { value: "事奉", label: "事奉" },
      { value: "回應呼召", label: "回應呼召" },
      { value: "敬拜", label: "敬拜" },
      { value: "悔改", label: "悔改" },
    ],
  },
  {
    value: "教會生活",
    label: "教會生活",
    sub_cat_data: [
      { value: "", label: "" },
      { value: "教會性質及根基", label: "教會性質及根基" },
      { value: "教會團契合一", label: "教會團契合一" },
      { value: "教會更新及復興", label: "教會更新及復興" },
      { value: "教會職事與傳道", label: "教會職事與傳道" },
      { value: "承傳", label: "承傳" },
      { value: "職事", label: "職事" },
      { value: "使命", label: "使命" },
      { value: "傳福音", label: "傳福音" },
      { value: "浸禮", label: "浸禮" },
      { value: "主餐", label: "主餐" },
      { value: "嬰孩奉獻禮", label: "嬰孩奉獻禮" },
      { value: "奉獻生命", label: "奉獻生命" },
      { value: "奉獻財物", label: "奉獻財物" },
      { value: "按立", label: "按立" },
      { value: "獻堂禮", label: "獻堂禮" },
      { value: "喪禮", label: "喪禮" },
      { value: "婚禮", label: "婚禮" },
      { value: "週年紀念", label: "週年紀念" },
    ],
  },
  {
    value: "崇拜特用",
    label: "崇拜特用",
    sub_cat_data: [
      { value: "", label: "" },
      { value: "將臨期", label: "將臨期" },
      { value: "聖誕節", label: "聖誕節" },
      { value: "預苦期", label: "預苦期" },
      { value: "宣召", label: "宣召" },
      { value: "始禮詩歌", label: "始禮詩歌" },
      { value: "榮耀頌", label: "榮耀頌" },
      { value: "三一頌", label: "三一頌" },
      { value: "禱告回應", label: "禱告回應" },
      { value: "奉獻", label: "奉獻" },
      { value: "終禮詩歌", label: "終禮詩歌" },
      { value: "祝福", label: "祝福" },
      { value: "阿們頌", label: "阿們頌" },
    ],
  },
  {
    value: "其他",
    label: "其他",
    sub_cat_data: [
      { value: "", label: "" },
      { value: "父母親恩", label: "父母親恩" },
      { value: "新年", label: "新年" },
      { value: "詩篇", label: "詩篇" },
      { value: "早晨詩歌", label: "早晨詩歌" },
      { value: "晚上詩歌", label: "晚上詩歌" },
      { value: "兒童詩歌", label: "兒童詩歌" },
      { value: "祈禱詩歌", label: "祈禱詩歌" },
      { value: "大自然", label: "大自然" },
      { value: "四季轉換", label: "四季轉換" },
      { value: "收成", label: "收成" },
      { value: "惜別", label: "惜別" },
      { value: "短歌", label: "短歌" },
    ],
  },
];

const language_ranges = [
  {
    value: "",
    label: "",
  },
  {
    value: "粵語",
    label: "粵語",
  },
  {
    value: "國語",
    label: "國語",
  },
  {
    value: "英語",
    label: "英語",
  },
];

const sub_cat_ranges = [
  {
    value: "",
    label: "",
  },
  { value: "聖父", label: "聖父" },
  { value: "聖子", label: "聖子" },
  { value: "聖靈", label: "聖靈" },
  { value: "三一真神", label: "三一真神" },
  { value: "神的永能", label: "神的永能" },
  { value: "神的威嚴與能力", label: "神的威嚴與能力" },
  { value: "神的權威與治權", label: "神的權威與治權" },
  { value: "神的聖潔", label: "神的聖潔" },
  { value: "神的慈愛", label: "神的慈愛" },
  { value: "神的憐憫", label: "神的憐憫" },
  { value: "神的榮耀", label: "神的榮耀" },
  { value: "神的信實", label: "神的信實" },
  { value: "神的真實和智慧", label: "神的真實和智慧" },
  { value: "神的仁慈", label: "神的仁慈" },
  { value: "神的公義", label: "神的公義" },
  { value: "神的創造", label: "神的創造" },
  { value: "神的饒恕", label: "神的饒恕" },
  { value: "神的恩典", label: "神的恩典" },
  { value: "神是王", label: "神是王" },
  { value: "神的國度", label: "神的國度" },
  { value: "神是保守者和拯救", label: "神是保守者和拯救" },
  { value: "神是牧者", label: "神是牧者" },
  { value: "神的保護", label: "神的保護" },
  { value: "神的同在", label: "神的同在" },
  { value: "神的供應", label: "神的供應" },
  { value: "神的看顧和保護", label: "神的看顧和保護" },
  { value: "神的應許", label: "神的應許" },
  { value: "神的引導", label: "神的引導" },
  { value: "神的尊貴", label: "神的尊貴" },
  { value: "神的盼望", label: "神的盼望" },
  { value: "神的主權", label: "神的主權" },
  { value: "神的審判", label: "神的審判" },
  { value: "神的掌權", label: "神的掌權" },
  { value: "耶穌基督臨世", label: "耶穌基督臨世" },
  { value: "耶穌基督的降生", label: "耶穌基督的降生" },
  { value: "耶穌基督的顯現", label: "耶穌基督的顯現" },
  { value: "耶穌基督地上生活和職事", label: "耶穌基督地上生活和職事" },
  { value: "耶穌基督凱旋進城", label: "耶穌基督凱旋進城" },
  { value: "耶穌基督的十架", label: "耶穌基督的十架" },
  { value: "耶穌基督受苦和受死", label: "耶穌基督受苦和受死" },
  { value: "耶穌基督的寶血", label: "耶穌基督的寶血" },
  { value: "耶穌基督的犧牲", label: "耶穌基督的犧牲" },
  { value: "耶穌基督的復活", label: "耶穌基督的復活" },
  { value: "耶穌基督升天及掌權", label: "耶穌基督升天及掌權" },
  { value: "耶穌基督的最後勝利", label: "耶穌基督的最後勝利" },
  { value: "耶穌基督的再來", label: "耶穌基督的再來" },
  { value: "耶穌基督的永在", label: "耶穌基督的永在" },
  { value: "耶穌基督道成肉身", label: "耶穌基督道成肉身" },
  { value: "耶穌基督的威嚴與能力", label: "耶穌基督的威嚴與能力" },
  { value: "耶穌基督的權威與治權", label: "耶穌基督的權威與治權" },
  { value: "耶穌基督的尊貴", label: "耶穌基督的尊貴" },
  { value: "耶穌基督對我們的愛", label: "耶穌基督對我們的愛" },
  { value: "耶穌基督的名字", label: "耶穌基督的名字" },
  { value: "耶穌基督是王", label: "耶穌基督是王" },
  { value: "耶穌基督是主", label: "耶穌基督是主" },
  { value: "耶穌基督是一切", label: "耶穌基督是一切" },
  { value: "耶穌基督是神的羔羊", label: "耶穌基督是神的羔羊" },
  { value: "耶穌基督是拯救者", label: "耶穌基督是拯救者" },
  { value: "耶穌基督是救贖主", label: "耶穌基督是救贖主" },
  { value: "耶穌基督是釋放者", label: "耶穌基督是釋放者" },
  { value: "耶穌基督是醫治者", label: "耶穌基督是醫治者" },
  { value: "耶穌基督是良友", label: "耶穌基督是良友" },
  { value: "耶穌基督是牧者", label: "耶穌基督是牧者" },
  { value: "耶穌基督是教師", label: "耶穌基督是教師" },
  { value: "耶穌基督是喜樂", label: "耶穌基督是喜樂" },
  { value: "耶穌基督的恩典", label: "耶穌基督的恩典" },
  { value: "耶穌基督的榮耀", label: "耶穌基督的榮耀" },
  { value: "耶穌基督是光", label: "耶穌基督是光" },
  { value: "耶穌基督是根基", label: "耶穌基督是根基" },
  { value: "耶穌基督的保護", label: "耶穌基督的保護" },
  { value: "耶穌基督的拯救", label: "耶穌基督的拯救" },
  { value: "耶穌基督的引導", label: "耶穌基督的引導" },
  { value: "耶穌基督的呼召", label: "耶穌基督的呼召" },
  { value: "耶穌基督的同在", label: "耶穌基督的同在" },
  { value: "耶穌基督的柔和、謙卑", label: "耶穌基督的柔和、謙卑" },
  { value: "耶穌基督是先知、祭司", label: "耶穌基督是先知、祭司" },
  { value: "耶穌基督是中保", label: "耶穌基督是中保" },
  { value: "耶穌基督是代求者", label: "耶穌基督是代求者" },
  { value: "聖靈的能力", label: "聖靈的能力" },
  { value: "聖靈的工作", label: "聖靈的工作" },
  { value: "聖靈的更新", label: "聖靈的更新" },
  { value: "聖靈的同在", label: "聖靈的同在" },
  { value: "聖靈的充滿", label: "聖靈的充滿" },
  { value: "聖靈是生命氣息", label: "聖靈是生命氣息" },
  { value: "聖靈真理教導", label: "聖靈真理教導" },
  { value: "保惠師", label: "保惠師" },
  { value: "使人成聖", label: "使人成聖" },
  { value: "認罪", label: "認罪" },
  { value: "確據", label: "確據" },
  { value: "救恩", label: "救恩" },
  { value: "新生命", label: "新生命" },
  { value: "更新", label: "更新" },
  { value: "朝聖", label: "朝聖" },
  { value: "屬靈勝利", label: "屬靈勝利" },
  { value: "屬靈戰爭", label: "屬靈戰爭" },
  { value: "靈裡饑渴慕義", label: "靈裡饑渴慕義" },
  { value: "感恩", label: "感恩" },
  { value: "抱負", label: "抱負" },
  { value: "委身及奉獻", label: "委身及奉獻" },
  { value: "門徒", label: "門徒" },
  { value: "順服", label: "順服" },
  { value: "降服", label: "降服" },
  { value: "安慰", label: "安慰" },
  { value: "鼓勵", label: "鼓勵" },
  { value: "管家職分", label: "管家職分" },
  { value: "信徒服侍", label: "信徒服侍" },
  { value: "信徒關懷", label: "信徒關懷" },
  { value: "忠心", label: "忠心" },
  { value: "勇氣", label: "勇氣" },
  { value: "工作", label: "工作" },
  { value: "休閒", label: "休閒" },
  { value: "教育及知識", label: "教育及知識" },
  { value: "音樂及歌唱", label: "音樂及歌唱" },
  { value: "婚姻", label: "婚姻" },
  { value: "家庭及家人", label: "家庭及家人" },
  { value: "肉體饑渴", label: "肉體饑渴" },
  { value: "身體醫治", label: "身體醫治" },
  { value: "內在平安", label: "內在平安" },
  { value: "世界和平", label: "世界和平" },
  { value: "正義", label: "正義" },
  { value: "盼望", label: "盼望" },
  { value: "我們對神的愛", label: "我們對神的愛" },
  { value: "我們對基督的愛", label: "我們對基督的愛" },
  { value: "彼此相愛", label: "彼此相愛" },
  { value: "愛的禮物", label: "愛的禮物" },
  { value: "彼此饒恕", label: "彼此饒恕" },
  { value: "重擔", label: "重擔" },
  { value: "罪惡", label: "罪惡" },
  { value: "恐懼", label: "恐懼" },
  { value: "悲痛", label: "悲痛" },
  { value: "焦慮與緊張", label: "焦慮與緊張" },
  { value: "永生", label: "永生" },
  { value: "合一", label: "合一" },
  { value: "信心", label: "信心" },
  { value: "異象", label: "異象" },
  { value: "見證", label: "見證" },
  { value: "喜樂", label: "喜樂" },
  { value: "禱告", label: "禱告" },
  { value: "信靠", label: "信靠" },
  { value: "聖潔", label: "聖潔" },
  { value: "事奉", label: "事奉" },
  { value: "回應呼召", label: "回應呼召" },
  { value: "敬拜", label: "敬拜" },
  { value: "悔改", label: "悔改" },
  { value: "教會性質及根基", label: "教會性質及根基" },
  { value: "教會團契合一", label: "教會團契合一" },
  { value: "教會更新及復興", label: "教會更新及復興" },
  { value: "教會職事與傳道", label: "教會職事與傳道" },
  { value: "承傳", label: "承傳" },
  { value: "職事", label: "職事" },
  { value: "使命", label: "使命" },
  { value: "傳福音", label: "傳福音" },
  { value: "浸禮", label: "浸禮" },
  { value: "主餐", label: "主餐" },
  { value: "嬰孩奉獻禮", label: "嬰孩奉獻禮" },
  { value: "奉獻生命", label: "奉獻生命" },
  { value: "奉獻財物", label: "奉獻財物" },
  { value: "按立", label: "按立" },
  { value: "獻堂禮", label: "獻堂禮" },
  { value: "喪禮", label: "喪禮" },
  { value: "婚禮", label: "婚禮" },
  { value: "週年紀念", label: "週年紀念" },
  { value: "將臨期", label: "將臨期" },
  { value: "聖誕節", label: "聖誕節" },
  { value: "預苦期", label: "預苦期" },
  { value: "宣召", label: "宣召" },
  { value: "始禮詩歌", label: "始禮詩歌" },
  { value: "榮耀頌", label: "榮耀頌" },
  { value: "三一頌", label: "三一頌" },
  { value: "禱告回應", label: "禱告回應" },
  { value: "奉獻", label: "奉獻" },
  { value: "終禮詩歌", label: "終禮詩歌" },
  { value: "祝福", label: "祝福" },
  { value: "阿們頌", label: "阿們頌" },
  { value: "父母親恩", label: "父母親恩" },
  { value: "新年", label: "新年" },
  { value: "詩篇", label: "詩篇" },
  { value: "早晨詩歌", label: "早晨詩歌" },
  { value: "晚上詩歌", label: "晚上詩歌" },
  { value: "兒童詩歌", label: "兒童詩歌" },
  { value: "祈禱詩歌", label: "祈禱詩歌" },
  { value: "大自然", label: "大自然" },
  { value: "四季轉換", label: "四季轉換" },
  { value: "收成", label: "收成" },
  { value: "惜別", label: "惜別" },
  { value: "短歌", label: "短歌" },
];

class OutlinedInputAdornments extends React.Component {
  state = {};

  // 1. song_code
  // 2. song_name
  // 3. main_cat
  // 4. sub_cat
  // 5. related_verses
  // 6. keywords
  // 7. composer
  // 8. orisong
  // 9. arrangedby
  // 10. lyrics_writer
  // 11. translator
  // 12. lyrics
  // 13. language
  // 14. publishing

  handleMainItemChange = (e) => {
    let found = false;
    main_cat_ranges[e.target.value].sub_cat_data
      ? main_cat_ranges[e.target.value].sub_cat_data.map((option) =>
          option.value === this.props.sub_cat ? (found = true) : []
        )
      : (found = false);
    this.props.handleMainItemChange(
      e,
      main_cat_ranges[e.target.value].value,
      found ? this.props.sub_cat : ""
    );
  };

  _handleKeyDown = (e) => {
    if (e.key === "Enter") {
      console.log("do validate");
      this.props.onEnter();
    }
  };

  render() {
    const { classes } = this.props;

    return (
      <div className="fl w-100">
        <article className="cf">
          <div className="fl w-50  pa1 tc">
            <TextField
              margin="normal"
              fullWidth
              id="song_code"
              value={this.props.song_code}
              onChange={this.props.handleChange("song_code")}
              className={classNames(classes.margin, classes.textField)}
              variant="outlined"
              label="編號"
              onKeyDown={this._handleKeyDown}
            />
          </div>
          <div className="fl w-50  pa1 tc">
            <TextField
              margin="normal"
              fullWidth
              id="song_name"
              value={this.props.song_name}
              onChange={this.props.handleChange("song_name")}
              className={classNames(classes.margin, classes.textField)}
              variant="outlined"
              label="歌名"
              onKeyDown={this._handleKeyDown}
            />
          </div>
        </article>

        <article className="cf">
          <div className="fl w-50 pa1 tc">
            <TextField
              margin="normal"
              fullWidth
              select
              name="main_cat"
              className={classNames(classes.margin, classes.textField)}
              variant="outlined"
              label="主分類"
              value={this.props.main_cat_item_index}
              onChange={this.handleMainItemChange}
              onKeyDown={this._handleKeyDown}
            >
              {main_cat_ranges.map((option, index) => (
                <MenuItem key={option.value} value={index}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </div>

          <div className="fl w-50 pa1  tc">
            <TextField
              margin="normal"
              fullWidth
              select
              className={classNames(classes.margin, classes.textField)}
              variant="outlined"
              label="次分類"
              value={this.props.sub_cat}
              onChange={this.props.handleChange("sub_cat")}
              onKeyDown={this._handleKeyDown}
            >
              {this.props.main_cat_item_index > 0
                ? main_cat_ranges[
                    this.props.main_cat_item_index
                  ].sub_cat_data.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))
                : sub_cat_ranges.map((option) => (
                    <MenuItem
                      key={option.value + option.main_item_index}
                      value={option.value}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
            </TextField>
          </div>
        </article>

        <article className="cf">
          <div className="fl w-50 pa1  tc">
            <TextField
              margin="normal"
              fullWidth
              id="scripture"
              value={this.props.related_verses}
              onChange={this.props.handleChange("related_verses")}
              className={classNames(classes.margin, classes.textField)}
              variant="outlined"
              label="經文"
              onKeyDown={this._handleKeyDown}
            />
          </div>

          <div className="fl w-50 pa1  tc">
            <TextField
              margin="normal"
              fullWidth
              id="keywords"
              value={this.props.keywords}
              onChange={this.props.handleChange("keywords")}
              className={classNames(classes.margin, classes.textField)}
              variant="outlined"
              label="關鍵字"
              onKeyDown={this._handleKeyDown}
            />
          </div>
        </article>

        <article className="cf">
          <div className="fl w-50 pa1  tc">
            <TextField
              margin="normal"
              fullWidth
              id="composer"
              value={this.props.composer}
              onChange={this.props.handleChange("composer")}
              className={classNames(classes.margin, classes.textField)}
              variant="outlined"
              label="作曲"
              onKeyDown={this._handleKeyDown}
            />
          </div>

          <div className="fl w-50 pa1  tc">
            <TextField
              margin="normal"
              fullWidth
              id="arranged_by"
              value={this.props.arrangedby}
              onChange={this.props.handleChange("arrangedby")}
              className={classNames(classes.margin, classes.textField)}
              variant="outlined"
              label="編曲"
              onKeyDown={this._handleKeyDown}
            />
          </div>
        </article>

        <article className="cf">
          <div className="fl w-50 pa1  tc">
            <TextField
              margin="normal"
              fullWidth
              id="lyrics_by"
              value={this.props.lyrics_writer}
              onChange={this.props.handleChange("lyrics_writer")}
              className={classNames(classes.margin, classes.textField)}
              variant="outlined"
              label="作詞"
              onKeyDown={this._handleKeyDown}
            />
          </div>

          <div className="fl w-50 pa1 tc">
            <TextField
              margin="normal"
              fullWidth
              id="original_song"
              value={this.props.orisong}
              onChange={this.props.handleChange("orisong")}
              className={classNames(classes.margin, classes.textField)}
              variant="outlined"
              label="原曲"
              onKeyDown={this._handleKeyDown}
            />
          </div>
        </article>

        <article className="cf">
          <div className="fl w-50 pa1 tc">
            <TextField
              margin="normal"
              fullWidth
              id="translated_by"
              value={this.props.translator}
              onChange={this.props.handleChange("translator")}
              className={classNames(classes.margin, classes.textField)}
              variant="outlined"
              label="譯詞"
              onKeyDown={this._handleKeyDown}
            />
          </div>

          <div className="fl w-50 pa1 tc">
            <TextField
              margin="normal"
              fullWidth
              id="lyrics"
              value={this.props.lyrics}
              onChange={this.props.handleChange("lyrics")}
              className={classNames(classes.margin, classes.textField)}
              variant="outlined"
              label="歌詞"
              onKeyDown={this._handleKeyDown}
            />
          </div>
        </article>

        <article className="cf">
          <div className="fl w-50 pa1 tc">
            <TextField
              margin="normal"
              fullWidth
              select
              className={classNames(classes.margin, classes.textField)}
              variant="outlined"
              label="語言"
              value={this.props.language}
              onChange={this.props.handleChange("language")}
              onKeyDown={this._handleKeyDown}
            >
              {language_ranges.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </div>
          <div className="fl w-50 pa1 tc">
            <TextField
              margin="normal"
              fullWidth
              id="publishing"
              value={this.props.publishing}
              onChange={this.props.handleChange("publishing")}
              className={classNames(classes.margin, classes.textField)}
              variant="outlined"
              label="機構"
              onKeyDown={this._handleKeyDown}
            />
          </div>
        </article>
      </div>
    );
  }
}

OutlinedInputAdornments.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(OutlinedInputAdornments);
