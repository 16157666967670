import React from "react";
import Button from "@material-ui/core/Button";
import OutlinedInputAdornments from "../components/OutlinedInputAdornments";
import OutlinedInputAdornmentsForBasicSearch from "../components/OutlinedInputAdornmentsForBasicSearch";
import ButtonAppBar from "../containers/ButtonAppBar";
import { withRouter } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

class MainPage extends React.PureComponent {
  // 1. song_code
  // 2. song_name
  // 3. main_cat
  // 4. sub_cat
  // 5. related_verses
  // 6. keywords
  // 7. composer
  // 8. orisong
  // 9. arrangedby
  // 10. lyrics_writer
  // 11. translator
  // 12. lyrics
  // 13. language
  // 14. basic_search_target
  // 15. publishing

  constructor(props) {
    super(props);
    this.state = {
      song_code: props.songCode,
      song_name: props.songName,
      main_cat: props.mainCat,
      main_cat_item_index: props.mainCatItemIndex,
      sub_cat: props.subCat,
      related_verses: props.relatedVerses,
      keywords: props.keywords,
      composer: props.composer,
      orisong: props.orisong,
      arrangedby: props.arrangedBy,
      lyrics_writer: props.lyricsWriter,
      translator: props.translator,
      lyrics: props.lyrics,
      language: props.language,
      basic_search_target: "",
      search_type: "",
      publishing: props.publishing,
    };
  }

  handleChange = (prop) => (event) => {
    //    console.log("MainPage handleChange");
    //    console.log(prop + " _ " + event.target.value);
    switch (prop) {
      case "song_code":
        //        console.log("SC1: " + event.target.value);
        this.props.setSongCode(event.target.value);
        break;
      case "song_name":
        //        console.log("SN1: " + event.target.value);
        this.props.setSongName(event.target.value);
        break;
      case "sub_cat":
        //        console.log("subcat1: " + event.target.value);
        this.props.setSubCat(event.target.value);
        break;
      case "related_verses":
        //        console.log("rv: " + event.target.value);
        this.props.setRelatedVerses(event.target.value);
        break;
      case "keywords":
        //console.log("kw: " + event.target.value);
        this.props.setKeywords(event.target.value);
        break;
      case "composer":
        //console.log("com: " + event.target.value);
        this.props.setComposer(event.target.value);
        break;
      case "orisong":
        //        console.log("os: " + event.target.value);
        this.props.setOrisong(event.target.value);
        break;
      case "arrangedby":
        //        console.log("ab: " + event.target.value);
        this.props.setArrangedBy(event.target.value);
        break;
      case "lyrics_writer":
        //console.log("lw: " + event.target.value);
        this.props.setLyricsWriter(event.target.value);
        break;
      case "translator":
        //console.log("tr: " + event.target.value);
        this.props.setTranslator(event.target.value);
        break;
      case "lyrics":
        //        console.log("ly: " + event.target.value);
        this.props.setLyrics(event.target.value);
        break;
      case "language":
        //        console.log("la: " + event.target.value);
        this.props.setLanguage(event.target.value);
        break;
      case "publishing":
        //        console.log("la: " + event.target.value);
        this.props.setPublishing(event.target.value);
        break;
      default:
    }
    this.setState({ [prop]: event.target.value });
  };

  handleMainItemChange = (event, main_cat_value, sub_cat_value) => {
    /*console.log("MainPage handleItemChange");
    console.log(
      "main_cat_value: " +
        main_cat_value +
        " target.value: " +
        event.target.value
    );
    */
    this.props.setMainCat(main_cat_value);
    this.props.setMainCatItemIndex(
      event.target.value === 0 ? "" : event.target.value
    );
    this.props.setSubCat(sub_cat_value);
    this.setState({
      main_cat: main_cat_value,
      main_cat_item_index: event.target.value === 0 ? "" : event.target.value,
      sub_cat: sub_cat_value,
    });
  };

  searchButtonClicked = () => {
    this.props.history.push({
      pathname: "/result",
      state: {
        song_code: this.state.song_code,
        song_name: this.state.song_name,
        main_cat: this.state.main_cat,
        sub_cat: this.state.sub_cat,
        related_verses: this.state.related_verses,
        keywords: this.state.keywords,
        composer: this.state.composer,
        orisong: this.state.orisong,
        arrangedby: this.state.arrangedby,
        lyrics_writer: this.state.lyrics_writer,
        translator: this.state.translator,
        lyrics: this.state.lyrics,
        language: this.state.language,
        basic_search_target: this.state.basic_search_target,
        search_type: "advance",
        publishing: this.state.publishing,
      },
    });
  };

  clearButtonClicked = () => {
    this.props.setSongCode("");
    this.props.setSongName("");
    this.props.setMainCat("");
    this.props.setMainCatItemIndex("");
    this.props.setSubCat("");
    this.props.setRelatedVerses("");
    this.props.setKeywords("");
    this.props.setComposer("");
    this.props.setOrisong("");
    this.props.setArrangedBy("");
    this.props.setLyricsWriter("");
    this.props.setTranslator("");
    this.props.setLyrics("");
    this.props.setLanguage("");
    this.props.setPublishing("");
    this.setState({
      song_code: "",
      song_name: "",
      main_cat: "",
      main_cat_item_index: "",
      sub_cat: "",
      related_verses: "",
      keywords: "",
      composer: "",
      orisong: "",
      arrangedby: "",
      lyrics_writer: "",
      translator: "",
      lyrics: "",
      language: "",
      basic_search_target: "",
      search_type: "",
      publishing: "",
    });
  };

  basicSearchButtonClicked = () => {
    this.props.history.push({
      pathname: "/result",
      state: {
        song_code: this.state.song_code,
        song_name: this.state.song_name,
        main_cat: this.state.main_cat,
        sub_cat: this.state.sub_cat,
        related_verses: this.state.related_verses,
        keywords: this.state.keywords,
        composer: this.state.composer,
        orisong: this.state.orisong,
        arrangedby: this.state.arrangedby,
        lyrics_writer: this.state.lyrics_writer,
        translator: this.state.translator,
        lyrics: this.state.lyrics,
        language: this.state.language,
        basic_search_target: this.state.basic_search_target,
        search_type: "basic",
      },
    });
  };

  render() {
    return (
      <div className="tc fl w-100">
        <ButtonAppBar />

        <div className="pa2" />

        <Tabs
          selectedIndex={this.props.tabIndex}
          onSelect={(tabIndex) => {
            this.props.setTabIndex({ tabIndex });
          }}
        >
          <TabList>
            <Tab>
              <header className="tc ph4">
                {this.props.tabIndex === 0 ? (
                  <h2 className="f5 f4-m f3-l fw2 black-90 mt0 lh-copy">
                    基本搜尋{" "}
                  </h2>
                ) : (
                  <h2 className="f5 f4-m f3-l fw2 black-50 mt0 lh-copy">
                    基本搜尋
                  </h2>
                )}
              </header>
            </Tab>
            <Tab>
              {" "}
              {this.props.tabIndex === 1 ? (
                <h2 className="f5 f4-m f3-l fw2 black-90 mt0 lh-copy">
                  進階搜尋
                </h2>
              ) : (
                <h2 className="f5 f4-m f3-l fw2 black-50 mt0 lh-copy">
                  進階搜尋
                </h2>
              )}
            </Tab>
          </TabList>
          <TabPanel></TabPanel>
          <TabPanel></TabPanel>
        </Tabs>

        {this.props.tabIndex ? (
          <div className="br3 mv2 mw7 mw7-ns center bg-light-gray pa3 ph3-ns">
            <OutlinedInputAdornments
              song_code={this.state.song_code}
              song_name={this.state.song_name}
              main_cat={this.state.main_cat}
              main_cat_item_index={this.state.main_cat_item_index}
              sub_cat={this.state.sub_cat}
              related_verses={this.state.related_verses}
              keywords={this.state.keywords}
              composer={this.state.composer}
              orisong={this.state.orisong}
              arrangedby={this.state.arrangedby}
              lyrics_writer={this.state.lyrics_writer}
              translator={this.state.translator}
              lyrics={this.state.lyrics}
              language={this.state.language}
              publishing={this.state.publishing}
              handleChange={this.handleChange}
              handleMainItemChange={this.handleMainItemChange}
              onEnter={this.searchButtonClicked}
            />

            <div className="mw-100">
              <Button
                variant="contained"
                color="primary"
                onClick={this.searchButtonClicked}
              >
                進階搜尋
              </Button>
              <div className="divider" />
              <Button variant="contained" onClick={this.clearButtonClicked}>
                清除
              </Button>
            </div>
          </div>
        ) : (
          <div className="br3 mv2 mw7 mw7-ns center bg-light-gray pa3 ph3-ns">
            <OutlinedInputAdornmentsForBasicSearch
              basic_search_target={this.state.basic_search_target}
              handleChange={this.handleChange}
              onEnter={this.basicSearchButtonClicked}
            />

            <div className="mw-100">
              <Button
                variant="contained"
                color="primary"
                onClick={this.basicSearchButtonClicked}
              >
                搜尋詩歌
              </Button>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(MainPage);
