import React from "react";
//import logo from "./logo.svg";
import "./App.css";
//import { HashRouter as Router, Route, Link } from "react-router-dom";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import MainPage from "./containers/MainPage";
import ResultPage from "./containers/ResultPage";
//import ButtonAppBar from './containers/ButtonAppBar';

//import {AppProvider, LoginContext} from './AppProvider';
import { AppProvider } from "./AppProvider";

function App() {
  const [tabIndex, setTabIndex] = React.useState(0);
  const [songCode, setSongCode] = React.useState("");
  const [songName, setSongName] = React.useState("");
  const [mainCat, setMainCat] = React.useState("");
  const [mainCatItemIndex, setMainCatItemIndex] = React.useState("");
  const [subCat, setSubCat] = React.useState("");
  const [relatedVerses, setRelatedVerses] = React.useState("");
  const [keywords, setKeywords] = React.useState("");
  const [composer, setComposer] = React.useState("");
  const [orisong, setOrisong] = React.useState("");
  const [arrangedBy, setArrangedBy] = React.useState("");
  const [lyricsWriter, setLyricsWriter] = React.useState("");
  const [translator, setTranslator] = React.useState("");
  const [lyrics, setLyrics] = React.useState("");
  const [language, setLanguage] = React.useState("");
  const [publishing, setPublishing] = React.useState("");
  const setTabIndex1 = (tabIndex1) => {
    setTabIndex(tabIndex1.tabIndex);
  };

  return (
    <AppProvider>
      <Router>
        <div>
          <Switch>
            <Route exact path="/">
              <MainPage
                tabIndex={tabIndex}
                songCode={songCode}
                songName={songName}
                mainCat={mainCat}
                mainCatItemIndex={mainCatItemIndex}
                subCat={subCat}
                relatedVerses={relatedVerses}
                keywords={keywords}
                composer={composer}
                orisong={orisong}
                arrangedBy={arrangedBy}
                lyricsWriter={lyricsWriter}
                translator={translator}
                lyrics={lyrics}
                language={language}
                publishing={publishing}
                setTabIndex={setTabIndex1}
                setSongCode={setSongCode}
                setSongName={setSongName}
                setMainCat={setMainCat}
                setMainCatItemIndex={setMainCatItemIndex}
                setSubCat={setSubCat}
                setRelatedVerses={setRelatedVerses}
                setKeywords={setKeywords}
                setComposer={setComposer}
                setOrisong={setOrisong}
                setArrangedBy={setArrangedBy}
                setLyricsWriter={setLyricsWriter}
                setTranslator={setTranslator}
                setLyrics={setLyrics}
                setLanguage={setLanguage}
                setPublishing={setPublishing}
              />
            </Route>
            <Route exact path="/Main">
              <MainPage
                tabIndex={tabIndex}
                songCode={songCode}
                songName={songName}
                mainCat={mainCat}
                mainCatItemIndex={mainCatItemIndex}
                subCat={subCat}
                relatedVerses={relatedVerses}
                keywords={keywords}
                composer={composer}
                orisong={orisong}
                arrangedBy={arrangedBy}
                lyricsWriter={lyricsWriter}
                translator={translator}
                lyrics={lyrics}
                language={language}
                publishing={publishing}
                setTabIndex={setTabIndex1}
                setSongCode={setSongCode}
                setSongName={setSongName}
                setMainCat={setMainCat}
                setMainCatItemIndex={setMainCatItemIndex}
                setSubCat={setSubCat}
                setRelatedVerses={setRelatedVerses}
                setKeywords={setKeywords}
                setComposer={setComposer}
                setOrisong={setOrisong}
                setArrangedBy={setArrangedBy}
                setLyricsWriter={setLyricsWriter}
                setTranslator={setTranslator}
                setLyrics={setLyrics}
                setLanguage={setLanguage}
                setPublishing={setPublishing}
              />
            </Route>
            <Route path="/result">
              <ResultPage />
            </Route>
          </Switch>
        </div>
      </Router>
    </AppProvider>
  );
}

export default App;
