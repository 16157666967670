import React from "react";
import { withRouter } from "react-router-dom";
import { searchAPI, basicSearchAPI } from "../functions/Api";
import CardList from "../components/CardList";
//import Scroll from "../components/Scroll";
import ButtonAppBar from "../containers/ButtonAppBar";
import Pagination from "@material-ui/lab/Pagination";
import { Box } from "@material-ui/core";

class ResultPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      responseArray: [],
      offset: 0,
      data: [],
      perPage: 10,
      currentPage: 1,
      pageCount: 0,
      card_expanded_list: [],
    };
  }

  componentWillMount() {
    //console.log("parameter: ", this.props);

    if (this.props.location.state === undefined) {
      this.props.history.push({
        pathname: "/",
      });
    } else {
      this.song_code = this.props.location.state.song_code;
      this.song_name = this.props.location.state.song_name;
      this.main_cat = this.props.location.state.main_cat;
      this.sub_cat = this.props.location.state.sub_cat;
      this.related_verses = this.props.location.state.related_verses;
      this.keywords = this.props.location.state.keywords;
      this.composer = this.props.location.state.composer;
      this.orisong = this.props.location.state.orisong;
      this.arrangedby = this.props.location.state.arrangedby;
      this.lyrics_writer = this.props.location.state.lyrics_writer;
      this.translator = this.props.location.state.translator;
      this.lyrics = this.props.location.state.lyrics;
      this.language = this.props.location.state.language;
      this.basic_search_target = this.props.location.state.basic_search_target;
      this.search_type = this.props.location.state.search_type;
      this.publishing = this.props.location.state.publishing;

      if (this.search_type === "advance") {
        this.searchApiFetch();
      } else {
        this.basicSearchApiFetch();
      }
    }
  }

  componentDidMount() {
    //console.log("I was triggered during componentDidMount");
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    /*console.log("componentDidUpdate prevProps: ", prevProps);
    console.log("componentDidUpdate prevState: ", prevState);
    console.log("componentDidUpdate snapshot: ", snapshot);
  */
  }

  searchApiFetch = async () => {
    if (
      (this.song_code === "" ||
        this.song_code.split(".").join("").length > 0) &&
      (
        this.song_code +
        this.song_name +
        this.main_cat +
        this.sub_cat +
        this.related_verses +
        this.keywords +
        this.composer +
        this.orisong +
        this.arrangedby +
        this.lyrics_writer +
        this.translator +
        this.lyrics +
        this.language +
        this.publishing
      ).replace(/[% ]/, "").length > 0
    ) {
      this.response_data = await searchAPI(
        this.song_code,
        this.song_name,
        this.main_cat,
        this.sub_cat,
        this.related_verses,
        this.keywords,
        this.composer,
        this.orisong,
        this.arrangedby,
        this.lyrics_writer,
        this.translator,
        this.lyrics,
        this.language,
        this.publishing
      );
      //console.log("searchApiFetch result: " + this.response_data);
      this.handleResponseData();
    } else {
      this.setState({ responseArray: "search_text_err" });
    }
  };

  basicSearchApiFetch = async () => {
    if (this.basic_search_target.replace(/[.% ]/, "").length > 0) {
      this.response_data = await basicSearchAPI(this.basic_search_target);
      //console.log("basicSearchAPI result: " + this.response_data);
      this.handleResponseData();
    } else {
      this.setState({ responseArray: "search_text_err" });
    }
  };

  handleResponseData = () => {
    if (this.response_data.length === 0) {
      this.setState({ responseArray: "no_result" });
    } else {
      this.setState({
        responseArray: this.response_data,
        card_expanded_list: new Array(this.state.perPage).fill(false),
        pageCount: Math.ceil(this.response_data.length / this.state.perPage),
      });
    }
  };

  handlePageChange = (e, page) => {
    this.setState({
      currentPage: page,
      offset: this.state.perPage * (page - 1),
      card_expanded_list: new Array(this.state.perPage).fill(false),
    });
  };

  handleCardExpansionChange = (e, i) => {
    const new_card_expanded_list = this.state.card_expanded_list.slice();
    new_card_expanded_list[i] = !new_card_expanded_list[i];
    this.setState({ card_expanded_list: new_card_expanded_list });
  };

  render() {
    if (this.state.responseArray === "no_result") {
      return (
        <div className="tc fl w-100">
          <ButtonAppBar />
          <h1 className="f4 bg-light-gray mv0 br3 br--top black-100 mv0 pv2 ph3">
            沒有搜尋結果
          </h1>
        </div>
      );
    } else if (this.state.responseArray === "search_text_err") {
      return (
        <div className="tc fl w-100">
          <ButtonAppBar />
          <h1 className="f4 bg-light-gray mv0 br3 br--top black-100 mv0 pv2 ph3">
            請輸入最少一項搜尋條件
          </h1>
        </div>
      );
    } else if (this.state.responseArray.length > 0) {
      return (
        <div className="tc fl w-100">
          <ButtonAppBar />
          <h1 className="f4 bg-light-gray mv0 br3 br--top black-100 mv0 pv2 ph3">
            搜尋結果
            {" (" +
              this.state.responseArray.length +
              ") " +
              this.basic_search_target}
          </h1>
          <Box
            display="flex"
            width="100%"
            alignItems="center"
            justifyContent="center"
          >
            <Pagination
              count={this.state.pageCount}
              defaultPage={1}
              siblingCount={1}
              page={this.state.currentPage}
              alignitems="center"
              justify="center"
              variant="outlined"
              shape="rounded"
              color="primary"
              onChange={this.handlePageChange}
            />
          </Box>
          <Box width="100%" alignItems="center" justifyContent="center">
            <CardList
              songs={this.state.responseArray.slice(
                this.state.offset,
                this.state.offset + this.state.perPage
              )}
              is_expanded={this.state.card_expanded_list}
              handleCardExpansionChange={this.handleCardExpansionChange}
              search_text={this.basic_search_target}
              search_lyrics={this.lyrics}
            />
          </Box>
          <Box
            display="flex"
            width="100%"
            alignItems="center"
            justifyContent="center"
          >
            <Pagination
              count={this.state.pageCount}
              defaultPage={1}
              siblingCount={1}
              page={this.state.currentPage}
              variant="outlined"
              shape="rounded"
              color="primary"
              onChange={this.handlePageChange}
            />
          </Box>
        </div>
      );
    } else {
      return (
        <div className="tc fl w-100">
          <ButtonAppBar />
          <h1 className="f4 bg-light-gray mv0 br3 br--top black-100 mv0 pv2 ph3">
            搜尋中
          </h1>
        </div>
      );
    }
  }
}

export default withRouter(ResultPage);
