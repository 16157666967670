import React from "react";
//import { IS_MEMBER, IS_NOT_MEMBER, DID_NOT_CHECK_MEMBER } from './ConfigFile';
import { DID_NOT_CHECK_MEMBER } from "./ConfigFile";

export const LoginContext = React.createContext();
//export const useLogin = ()=>React.useContext(LoginContext)

export const AppProvider = (props) => {
  const [isLogin, setLogin] = React.useState(false);
  const [yanFookMemberPermission, setYanFookMemberPermission] = React.useState(
    DID_NOT_CHECK_MEMBER
  );
  return (
    <LoginContext.Provider
      value={{
        isLogin,
        setLogin,
        yanFookMemberPermission,
        setYanFookMemberPermission,
      }}
    >
      {props.children}
    </LoginContext.Provider>
  );
};
