import React from "react";
import Card from "./Card";

const CardList = (props) => {
  return (
    <div>
      {Array.from(props.songs).map((user, i) => {
        return (
          // 1. song_code
          // 2. song_name
          // 3. main_cat
          // 4. sub_cat
          // 5. related_verses
          // 6. keywords
          // 7. composer
          // 8. orisong
          // 9. arrangedby
          // 10. lyrics_writer
          // 11. translator
          // 12. lyrics
          // 13. language
          // 14. remarks
          // 15. publishing
          <Card
            key={i}
            index={i}
            id={props.songs[i].SongID}
            song_name={props.songs[i].SongName}
            main_cat={props.songs[i].MainCat}
            sub_cat={props.songs[i].SubCat}
            keywords={props.songs[i].Keyword}
            related_verses={props.songs[i].Bible}
            orisong={props.songs[i].OriSong}
            arrangedby={props.songs[i].ArrangedBy}
            composer={props.songs[i].Writer}
            lyrics_writer={props.songs[i].Lyrics}
            translator={props.songs[i].Translate}
            lyrics={props.songs[i].LyricsText}
            song_code={props.songs[i].SongCode}
            remarks={props.songs[i].Remarks}
            related_song={props.songs[i].RelatedSongs}
            language={props.songs[i].Language}
            publishing={props.songs[i].Publishing}
            album={props.songs[i].Album}
            copyright={props.songs[i].Copyright}
            is_expanded={props.is_expanded[i]}
            search_text={props.search_text}
            search_lyrics={props.search_lyrics}
            handleCardExpansionChange={props.handleCardExpansionChange}
          />
        );
      })}
    </div>
  );
};

export default CardList;
